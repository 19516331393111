<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h1 class="text-black">App Settings</h1>
    </div>

    <div>
      <b-row>
        <b-col md="3">
          <div class="card sideCard container">
            <ul>
              <li
                :class="activeComponent == 'Banner' ? 'active d-flex' : 'd-flex'"
                @click="handleActiveComponent('Banner')"
              >
                <img
                  v-if="activeComponent == 'Banner'"
                  src="../../../assets/images/svg/banner-active.svg"
                />
                <img v-else src="../../../assets/images/svg/banner.svg" />
                <div class="ml-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  Banner Management
              </div>
              </li>
              <li
                :class="activeComponent == 'Activity' ?  'active d-flex' : 'd-flex'"
                @click="handleActiveComponent('Activity')"
              >
                <img
                  v-if="activeComponent == 'Activity'"
                  src="../../../assets/images/svg/activity-active.svg"
                />
                <img v-else src="../../../assets/images/svg/activity.svg" />
                <div class="ml-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                  Activity log
              </div>
              </li>
              <li
                :class="activeComponent == 'Priority' ? 'active d-flex' : 'd-flex'"
                @click="handleActiveComponent('Priority')"
              >
                <img
                  v-if="activeComponent == 'Priority'"
                  src="../../../assets/images/svg/priority-active.svg"
                />
                <img v-else src="../../../assets/images/svg/priority.svg" />
                <div class="ml-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                Priority
                </div>
              </li>
              <li
                :class="activeComponent == 'Contact' ? 'active d-flex' : 'd-flex'"
                @click="handleActiveComponent('Contact')"
              >
                <img
                  v-if="activeComponent == 'Contact'"
                  src="../../../assets/images/svg/contact-active.svg"
                />
                <img v-else src="../../../assets/images/svg/contact.svg" />
                <div class="ml-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                Contact
                </div>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col md="9">
          <div class="container-fluid">
            <div v-if="activeComponent == 'Banner'">
              <banner-managment />
            </div>
            <div v-if="activeComponent == 'Activity'">
              <activitylog />
            </div>
            <div v-if="activeComponent == 'Priority'">
              <priority />
            </div>
            <div v-if="activeComponent == 'Contact'">
              <contact />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import BannerManagment from "./Banner-Managment.vue";
import Activitylog from "./Activity-Log.vue";
import Priority from "./Priority.vue";
import Contact from "./Contact.vue";
export default {
  components: {
    draggable,
    BannerManagment,
    Activitylog,
    Priority,
    Contact,
  },
  data() {
    return {
      data: {},
      clientStoreData: [
        { name: "Noon" },
        { name: "Sephora" },
        { name: "American Eagle" },
        { name: "Amazon" },
        { name: "Namshi" },
        { name: "H&M" },
      ],
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      activeComponent: "Banner",
    };
  },
  methods: {
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleActiveComponent(item) {
      this.activeComponent = item;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.container {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 24px;
}
.sideCard ul {
  list-style-type: none;
  margin-top: 2rem;
}
.sideCard ul li {
  padding: 1rem 2rem;
  cursor: pointer;
}
html {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.sideCard li.active {
  background: #f4f9fd;
  border-radius: 14px;
  color: #0a1629;
}
.sideCard .btn.btn-default:hover {
  box-shadow: 0 8px 25px -8px #82868b;
}
.sideCard li.active div{
  color: #3F8CFF;
}
</style>