<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">{{ app }}
      <div class="custom-head-contain d-flex">
        <div
          class="email"
          @click="handleActiveComponent('email')"
          :class="activeComponent == 'email' ? 'active' : ''"
        >
          Email
        </div>
        <div
          class="whatsapp"
          @click="handleActiveComponent('whatsapp')"
          :class="activeComponent == 'whatsapp' ? 'active' : ''"
        >
          Whatsapp
        </div>
      </div>
    </div>
    
    <div v-if="isLoading" style="display: flex;justify-content: center;height: 200px;align-items: center;">
          <b-spinner style="width: 3rem; height: 3rem;" variant="secondary"
            />
          </div>
    <div v-else class="drop-zone">
      <b-card
        no-body
        class="card-revenue-budget dash-total-card mb-2 drag-el p-5"
        v-if="activeComponent == 'email'"
      >
        <div class="form-group">
          <label for="inputEmail4">Email</label>
          <input
            type="email"
            v-model="applicationData.email"
            class="form-control"
            placeholder="example@email.com"
          />
        </div>
        <div class="modal-footer1 pr-0">
          <button type="button" @click="updateData('email')" class="btn btn-primary">Submit</button>
        </div>
      </b-card>
      <b-card
        no-body
        class="card-revenue-budget dash-total-card mb-2 drag-el p-5"
        v-if="activeComponent == 'whatsapp'"
      >
        <div class="form-group" >
          <label class="mb-1" for="inputEmail4">Whatsapp Number</label>
          
           <!-- <vue-tel-input v-model="applicationData.whatsapp"></vue-tel-input> -->
          <div style="width: 100%;display: flex;">
          <input
            type="text"
            placeholder="+91"
            v-model="applicationData.country_code"
            class="form-control"
            style="border-radius: 14px 0px 0px 14px;width:12%;"
          />
          <input
            type="number"
            v-model="applicationData.whatsapp"
            style="border-radius: 0px 14px 14px 0px;border-left:none;width:88%;"
            class="form-control"
            placeholder="12 343 2454"
          />
        </div>
         </div>
        <div class="modal-footer1 pr-0">
          <button type="button" @click="updateData('wh')" class="btn btn-primary">Submit</button>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import VSelect from "vue-select";
// import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/vue-tel-input.css';
export default {
  components: {
    draggable,
    VSelect,
    // VueTelInput
  },
  data() {
    return {
      selectedValue: "",
      countryCode: '',
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      data: {},
      isLoading:true,
      applicationData:{},
      inlineRadio: "itcan",
      activeComponent: "email",
    };
  },
  computed: {
    app(){
         this.getAppSetting()
        }
  },
  mounted(){
    this.getAppSetting();
   },
  methods: {
    getAppSetting(){
      this.$http.post(`${this.baseURL}/applicationsetting/index/${this.$store.state.application.id}`).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.applicationData = res.data.data
           this.isLoading = false
          })
    },
    updateData(value){
      let data = {
        application_id: this.$store.state.application.id
      }
      if(value == 'email'){
       data = { ...data,email:this.applicationData.email}
      }else if(value == 'wh'){
        data = { ...data,whatsapp:this.applicationData.whatsapp,country_code:this.applicationData.country_code}
      }
      this.$http.post( `${this.baseURL}/applicationsetting/update`,data).then((json)=>{
            console.log(json.data.data,"json-=-=")
            if (json.data.status == 200) {
              this.getAppSetting()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleActiveComponent(item) {
      this.activeComponent = item;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.custom-head-contain {
  background: #e6edf5;
  border-radius: 24px;
  .email,
  .whatsapp {
    padding: 7px 35px;
  }
  .active {
    background: #3f8cff;
    border-radius: 24px;
    color: #fff;
  }
}
.custom-radio {
  border: 1px solid #d8e0f0;
  border-radius: 10px;
  color: #000 !important;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  // 
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select {
  max-width: none;
}
</style>
