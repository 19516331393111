<template>
  <section id="dashboard-ecommerce">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h3 class="text-black">Priority{{ app }}</h3>
    </div>

    <div v-if="isLoading" style="display: flex;justify-content: center;height: 200px;align-items: center;">
          <b-spinner style="width: 3rem; height: 3rem;" variant="secondary"
            />
          </div>
    <div v-else class="drop-zone">
      <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el p-5">
        <label for="inputEmail4">Priority</label>
        <VRadioGroup @change="changeRadio" v-model="inlineRadio" inline>
          <b-row>
            <b-col md="2" class=" mr-1" >
              <VRadio label="Itcan" value="itcan" />
            </b-col>
            <b-col md="2" class="" >
              <VRadio label="Affiliate" value="affiliate" />
            </b-col>
          </b-row>
        </VRadioGroup>
      </b-card>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      selectedValue: "",
      data: {},
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      inlineRadio: "itcan",
      applicationData:{},
      isLoading:true,
    };
  },
  computed: {
    app(){
         this.getAppSetting()
        }
  },
  mounted(){
    this.getAppSetting();
   },
  methods: {
    getAppSetting(){
      this.$http.post(`${this.baseURL}/applicationsetting/index/${this.$store.state.application.id}`).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.applicationData = res.data.data
            this.inlineRadio = this.applicationData.prority
            this.isLoading= false
          })
    },
    changeRadio(){
      let data = {
        application_id: this.$store.state.application.id,
        prority: this.inlineRadio
      }
      this.$http.post( `${this.baseURL}/applicationsetting/update`,data).then((json)=>{
            console.log(json.data.data,"json-=-=")
            if (json.data.status == 200) {
              this.getAppSetting()
              this.$swal({
                title: "Submitted",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Submitted SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => {return `${item}:${json.data.data[item]}`}) : json.data.message
                  ? `${json.data.message}` 
                    : "Error"
              this.$swal({
                title: "Error!!",
                text: text,
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
  },
};
</script>

<style lang="scss">
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.custom-radio{
  color: #000 !important;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.v-label{
  margin-top: 5px;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-dialog {
  // 
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select {
  max-width: none;
}
</style>
