<template>
  <section id="dashboard-ecommerce" class="font-14">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h3 class="text-black">Activity Log{{ search }}{{ app }}</h3>
      <div>
        
        <!-- <button
          id="popover-button-variant"
          href="#"
          tabindex="0"
          type="button"
          class="btn notofication-btn mr-1"
        >
          <div>
            <img src="../../../assets/images/client/copy.png" alt="Snow" />
          </div>
        </button> -->
        <!-- <button type="button" class="btn notofication-btn mr-1">
          <img src="../../../assets/images/client/import.svg" alt="Snow" />
        </button> -->
        <button id="popover-button-bottom" href="#" tabindex="0" type="button" class="btn notofication-btn mr-1">
          <div>
            <img src="../../../assets/images/client/filter.svg" alt="Snow" />
          </div>
        </button>
        <b-popover ref="popover" target="popover-button-bottom" triggers="hover" placement="Left"
          class="custom-popover pop-btn" :show.sync="popoverShow1">
          <div class="" style="border: none;width:8cm">
            <flat-pickr v-model="date" :config="{
              dateFormat: 'd/m/Y',
              static:true,
              mode: 'range',
              inline:true
            }" @input="getFilterData($event)" />
              <button @click="date = ''" class="btn btn-sm btn-primary mt-1 float-right">Clear</button>
            <!-- <b-form-datepicker id="example-datepicker" v-model="filter" class="mb-2"></b-form-datepicker> -->
            <!-- <v-select  v-model="filter" class="" @input="handelChangeFilter" shadow placeholder="Select" :options="['All','Active','Inactive']"> -->
            <!-- </v-select> -->
          </div>
        </b-popover>
        <button type="button" class="btn notofication-btn mr-1" @click="exportToCSV"  v-b-tooltip.hover.v-primary title="Export CSV">
          <img src="../../../assets/images/client/export.svg" alt="Snow" />
        </button>
        <!-- <button
          id="popover-button-bottom"
          href="#"
          tabindex="0"
          type="button"
          class="btn notofication-btn mr-1"
        >
          <div>
            <img
              src="../../../assets/images/client/filter.svg"
              alt="Snow"
            />
          </div>
        </button> -->
      </div>
    </div>

    <b-modal id="showmodal" size="md" ref="showmodal" class="custom--model">
          <template #modal-header>
            <div style="width:80%;" class="form-row">
              <div class="col-md-12">
                <h2>ActivityLog </h2>
              </div>
            </div>
            <b-button @click="$refs.showmodal.hide()" variant="link" class="closeIcon" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </b-button>
          </template>
          <b-row>
            <b-col md="6">
              <div class="d-flex">
                <div>
                <div class="mb-half">Name</div>
                  <div class="font-weight-bolder text-black mb-half">
                    {{ showItem.user_id ? showItem.user_id : '(not set)'  }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Comment</div>
                  <div class="font-weight-bolder">{{ showItem.comment }}</div>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">IP</div>
                <div class="font-weight-bolder h5 text-black">{{ showItem.ip }}</div>
              </div>
            </b-col>
            <b-col md="6" class="d-flex">
              <div>
                <div class="mb-half">Date/Time</div>
                <div class="font-weight-bolder h5 text-black">{{ showItem.created_at }}</div>
              </div>
            </b-col>
            </b-row>
          </b-modal>
    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="activityData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
      <draggable
        v-model="activityData"
        handle=".draggable-task-handle"
        tag="ul"
        class="todo-task-list media-list draggable-task-handle d-inline"
      >
        <b-card
          no-body
          class="card-revenue-budget dash-total-card mb-2 drag-el"
          v-for="(item,index) in activityData"
          @dblclick="handelShow(item)"
          :key="index"
        >
          <b-row class="p-1">
            <b-col md="3" class="d-flex  pl-2">
              <div class="d-flex ">
                <div>
                  <div class="mb-half">Date/Time</div>
                  <div class="font-weight-bolder text-black">
                    {{item.created_at}}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex ">
              <div>
                <div class="mb-half">User</div>
                <div class="font-weight-bolder text-black">
                 {{item.user_id}}
                </div>
              </div>
            </b-col>
            <b-col md="2" class="d-flex ">
              <div>
                <div class="mb-half">Event type</div>
                <div class="font-weight-bolder text-black">Operation</div>
              </div>
            </b-col>
            <b-col md="3" class="d-flex ">
              <div>
                <div class="mb-half">Description</div>
                <div class="font-weight-bolder text-black">{{item.comment}}</div>
              </div>
            </b-col>
            
           
            <b-col md="2" class="d-flex justify-content-center align-items-center">
              <button type="button" class="btn btn-primary" style="border-radius: 16px; padding: 8px 20px;"
                v-b-modal="'myModal'" 
            @click="handelShow(item)"> View</button>
            </b-col>
          </b-row>
        </b-card>
      </draggable>
      <div class="no-results" :class="{ show: !activityData.length }"></div>
    </div>

   
     <button v-if="!isLoading && activityData.length" type="button" class="w-auto btn slide-btn">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>
    </button>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import json2csv from 'json2csv';
import moment from 'moment';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
export default {
  components: {
    draggable,
    flatPickr
  },
  data() {
    return {
        selectedValue:'',
      data: {},
      pageNo:1,
      date:'',
      isLoading:false,
      paginationStart:0,
      paginationEnd:10,
      paginationLength:0,
      activityData: [],
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      showItem:{}
    };
  },
  mounted(){
    this.getActivity()
  },
  created() {}, 
  computed:{
      search() {
          // let data = []
          let search = this.$store.state.searchData
          // data = this.activityData1.filter(item => ((item.status == 1 ? 'Active' : 'Inactive').toLowerCase().includes(search) 
          //                                              || item.title.replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
          // this.activityData = data
          this.getSearch(search);
        },
        app(){
          if(this.$store.state.application.id){
            let sidebar = this.$store.state.sidebar
            this.getActivity();
          }
        }
    },
  methods: {
    
    getFilterData: _.debounce(function (e) {
      this.date = e
      this.getActivity()
    }, 1000),
    handelShow(item){
      this.$refs.showmodal.show()
      this.popoverShow3={}
      this.showItem = item
    },
     exportToCSV() {
      const csvData = json2csv.parse(this.activityData);
      const link = document.createElement('a');
      link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
      link.download = 'ActivityLog.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
     },
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/activity/search`, { search: search, pageno: this.pageNo},
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.activityData = res.data.data
          this.paginationLength = res.data.totaldata
          this.activityData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),pagechange(){
      if(this.pageNo>(this.paginationLength/10)){
        this.pageNo = parseInt(this.paginationLength/10)
      }
      if(this.pageNo == 0){
        this.pageNo = 1
      }
        this.paginationStart = (this.pageNo*10)-10
        this.paginationEnd = (this.pageNo*10)
      let search = this.$store.state.searchData
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/activity/search`, { search: search, pageno: this.pageNo}).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.activityData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.activityData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/activity/index/${this.pageNo}`).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.activityData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.activityData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search)
          } else {
            this.normalPaginationHandelClick(search)
          }
        }
      }
    },
    async getActivity(){
      this.isLoading = true
        let date = this.date.toString().replace(/\s/g,'');
        let data = { 
          pageno: 1,
          startDate:date ? date.includes('to') ? moment(date.split('to')[0], "DD/MM/YYYY").format("YYYY/MM/DD") : moment(date, "DD/MM/YYYY").format("YYYY/MM/DD") : null,
          endDate:date ? date.includes('to') ? moment(date.split('to')[1], "DD/MM/YYYY").format("YYYY/MM/DD") : null : null
        }
      this.$http.post(`${this.baseURL}/activity/index`,data).then((res)=>{
            console.log(res.data.data,"res-=-=")
            this.activityData=res.data.data
            // this.pageNo=res.data.page
            this.paginationLength = res.data.totaldata
            this.activityData1=res.data.data
            this.isLoading = false
          })
        },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  // width: 200px;
  max-width:10cm;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select{
    max-width: none;
}
</style>
