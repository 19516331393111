<template>
  <section id="dashboard-ecommerce" class="font-14">
    <div class="sec1 d-flex justify-content-between pb-1">
      <h3 class="text-black d-flex">
        <div @click="handleBack" class="mr-1">
          <feather-icon size="24" icon="ArrowLeftIcon" class="text-body" />
        </div>
        Banner Details
      </h3>
    </div>
    
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div v-else class="drop-zone">
      <b-card no-body class="card-revenue-budget dash-total-card mb-2 drag-el p-3">

        <validation-observer ref="submitPrevent" #default="{ invalid }">
          <b-form @submit.prevent="addBannerHandelClick">
            <b-row>
              
              <b-col md="12" class="pl-2">
                <b-form-checkbox v-model="status" :checked="status" class="my-checkbox ml-1" name="check-button" switch>
                  <span class="switch-icon-left">
                    Active
                  </span>
                  <span class="switch-icon-right">
                    Inactive
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col md="5" class="pl-2">

                <b-form-group class="mb-2" label="Banner Title (English)">
                  <validation-provider #default="{ errors }" name="Banner Title (English)" rules="required">
                    <b-form-input v-model="bannerTitleEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Banner Title (English)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" class="pl-2">

                <b-form-group class="mb-2" label="Banner Title (Arabic)">
                  <validation-provider #default="{ errors }" name="Banner Title (Arabic)" rules="required">
                    <b-form-input v-model="bannerTitleAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Banner Title (Arabic)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5"  v-if="[1,2,4].includes($store.state.application.id)" class="pl-2">

                <b-form-group v-if="[1,2,4].includes($store.state.application.id)" class="mb-2" label="Short Description (English)">
                  <validation-provider #default="{ errors }" name="Short Description (English)" rules="required">
                    <b-form-input v-if="[1,2,4].includes($store.state.application.id)" v-model="shortDesEn" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Short Description (English)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5"  v-if="[1,2,4].includes($store.state.application.id)" class="pl-2">

                <b-form-group v-if="[1,2,4].includes($store.state.application.id)" class="mb-2" label="Short Description (Arabic)">
                  <validation-provider #default="{ errors }" name="Short Description (Arabic)" rules="required">
                    <b-form-input v-if="[1,2,4].includes($store.state.application.id)" v-model="shortDesAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Short Description (Arabic)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5"  v-if="[1,2,3,4,5].includes($store.state.application.id)" class="pl-2">
                <b-form-group v-if="[1,2,3,4,5].includes($store.state.application.id)" class="mb-2" label="Start Date">
            <flat-pickr v-if="[1,2,3,4,5].includes($store.state.application.id)" class="form-control" v-model="startDate" :config="{
              dateFormat: 'd/m/Y',
              static:true,
            }" @input="getFilterData($event)" />
            </b-form-group>
            </b-col>
              <b-col md="5" v-if="[1,2,3,4,5].includes($store.state.application.id)" class="pl-2">
                <b-form-group v-if="[1,2,3,4,5].includes($store.state.application.id)" class="mb-2" label="End Date">
            <flat-pickr v-if="[1,2,3,4,5].includes($store.state.application.id)" class="form-control" v-model="endDate" :config="{
              dateFormat: 'd/m/Y',
              static:true,
            }" @input="getFilterData($event)" />
            </b-form-group>
            </b-col>
              <b-col md="2"  class="pl-2">
                </b-col>
              <b-col v-if="[4].includes($store.state.application.id)" md="12" class="pl-2" style="vertical-align: middle;">
                <b-form-checkbox v-if="[4].includes($store.state.application.id)" v-model="isUsedToDeal" class="mr-0 mt-50" name="is-rtl"
                    inline />
                    <label class="h5">is this coupon used for deal</label>
              </b-col>
              <b-col v-if="[1,2].includes($store.state.application.id)" md="5" class="pl-2">
                <b-form-group v-if="[1,2].includes($store.state.application.id)" class="mb-2" label="Interface">
                  <validation-provider #default="{ errors }" name="Interface" rules="required">
                    <v-select v-if="[1,2].includes($store.state.application.id)" v-model="interFace" class="" placeholder="Select" label="platform_name"
                      :options="interfaceData">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" class="pl-2">
                <b-form-group class="mb-2" label="Connect to">
                  <validation-provider #default="{ errors }" name="Connect to" rules="required">
                    <v-select v-model="connectTo" @input="handelClickConnectTo()" class="" placeholder="Select"
                      :options="connectToData">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group class="mb-2" label="Sequence">
                  <validation-provider #default="{ errors }" name="Sequence" rules="required|integer">
                    <b-form-input type="number" v-model="sequence" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Sequence" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="connectTo == 'Client'" md="5" class="pl-2">

                <b-form-group v-if="connectTo == 'Client'" class="mb-2" label="Client">
                  <validation-provider #default="{ errors }" name="Client" rules="required">
                    <v-select v-if="connectTo == 'Client'" v-model="client" class="" label="name_en" placeholder="Select" :options="clientData">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="connectTo == 'Coupon'" md="5" class="pl-2">

                <b-form-group v-if="connectTo == 'Coupon'" class="mb-2" label="Coupon">
                  <validation-provider #default="{ errors }" name="Coupon" rules="required">
                    <v-select v-if="connectTo == 'Coupon'" v-model="coupon" class="" label="code" placeholder="Select" :options="offersData">
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col v-if="connectTo == 'Custom-URL'" md="5" class="pl-2">

                <b-form-group v-if="connectTo == 'Custom-URL'" class="mb-2" label="Custom-URL">
                  <validation-provider #default="{ errors }" name="Custom-URL" rules="required">
                    <b-form-input v-if="connectTo == 'Custom-URL'" v-model="customUrl" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Custom-URL" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="5" class="pl-2">
                <b-form-group class="mb-2" label="Image URL (English)">
                  <validation-provider #default="{ errors }" name="Image URL (English)" rules="required">
                    <b-form-input v-model="imageUrl" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Image URL (English)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" class="pl-2">
                <b-form-group class="mb-2" label="Image URL (Arabic)">
                  <validation-provider #default="{ errors }" name="Image URL (Arabic)" rules="required">
                    <b-form-input v-model="imageUrlAr" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Image URL (Arabic)" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" class="pl-2">
                <b-form-group class="mb-2" label="Redirection URL">
                  <validation-provider #default="{ errors }" name="Redirection URL" rules="required">
                    <b-form-input v-model="redirectUrl" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Redirection URL" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" class="pl-2">
                <b-form-group class="mb-2" label="Image Alt Text">
                  <validation-provider #default="{ errors }" name="Image Alt Text" rules="required">
                    <b-form-input v-model="imgAltText" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Image Alt Text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5"  v-if="[1,2,3,4,5].includes($store.state.application.id)" class="pl-2">
                <b-form-group v-if="[1,2,3,4,5].includes($store.state.application.id)" class="mb-2" label="Text Color">
                  <validation-provider #default="{ errors }" name="Text Color" rules="required">
                    <b-form-input v-if="[1,2,3,4,5].includes($store.state.application.id)" v-model="textColor" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Text Color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5" v-if="[1,2,3,4,5].includes($store.state.application.id)" class="pl-2">
                <b-form-group v-if="[1,2,3,4,5].includes($store.state.application.id)" class="mb-2" label="Background Color">
                  <validation-provider #default="{ errors }" name="Background Color" rules="required">
                    <b-form-input v-if="[1,2,3,4,5].includes($store.state.application.id)" v-model="backgroundColor" :state="errors.length > 0 ? false : null"
                      placeholder="Enter Background Color" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" class="pl-2">
                <button type="button" style="float:right" @click="addBannerHandelClick" class="btn btn-primary">Submit</button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer } from "@validations";
import VSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import { mapState } from 'vuex';
export default {
  components: {
    draggable,
    ValidationProvider,
    ValidationObserver,
    VSelect,
    flatPickr
  },
  props: ['editItemId'],
  data() {
    return {
      selectedValue: "",
      connectToType: '',
      bannerTitleEn: '',
      bannerTitleAr: '',
      required, integer,
      connectTo: '',
      connectToData: ['Client', 'Coupon', 'Custom-URL'],
      clientData: [],
      clientData1: [],
      sequence: 0,
      sequence1: 0,
      data: {},
      status:false,
      client: {},
      couponData: [],
      interfaceData:[],
      isUsedToDeal:'',
      interFace:'',
      shortDesAr:'',
      shortDesEn:'',
      imgAltText:'',
      redirectUrl:'',
      textColor:'',
      backgroundColor:'',
      coupon: {},
      customUrl: '',
      startDate:moment(new Date()).format('DD/MM/Y'),
      endDate:moment(new Date()).format('DD/MM/Y'),
      baseURL: process.env.VUE_APP_BASE_URL,
      host: window.location.origin,
      categoriesData: [
        { name: "Banner.jpg" },
        { name: "Banner1.jpg" },
        { name: "Banner2.jpg" },
      ],
      popoverShow: false,
      popoverShow1: false,
      value: "1",
      edit: false,
      imageUrl:'',
      imageUrlAr:'',
      offersData: [],
      offersData1: [],
      isLoading:false,
    };
  },
  watch: {
  '$store.state.application': {
    handler: 'handleBack',
    immediate: false,
  },
  },
  mounted() {
    
    if (this.editItemId) {
      this.getEditItem(this.editItemId)
    }else{
      this.isLoading = false
      this.$http.post(`${this.baseURL}/banners/get-sequence`).then((json) => {
      console.log(json.data.sequence, "data-=-=--")
      this.sequence = json.data.sequence
      this.sequence1 = json.data.sequence
    })
    }
    this.getCategories()
  },
  created() { },
  computed: {
    ...mapState(['sidebar']),
  },
  methods: {
    getEditItem(id) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/banners/view/${id}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((json) => {
          this.editItem = json.data.data
          this.bannerTitleEn = this.editItem.title,
            this.bannerTitleAr = this.editItem.title_ar,
            this.connectTo = this.editItem.connect_to,
            this.imageUrl = this.editItem.image_url,
            this.imageUrlAr = this.editItem.image_url_ar,
            this.sequence = this.editItem.sequence
          this.customUrl = this.editItem.custom_url,
            this.status = this.editItem.status  == 1? true : false,
            this.client = this.editItem.client_id,
            this.coupon = this.editItem.coupon_id,
            this.isUsedToDeal = this.editItem.is_used_to_deal == 1? true : false,
            this.interface = this.editItem.interface_id,
            this.shortDesAr = this.editItem.short_des_ar,
            this.shortDesEn = this.editItem.short_des_en,
            this.startDate = this.editItem.start_date.split(' ')[0].split('-').reverse().join('/'),
            this.endDate = this.editItem.end_date.split(' ')[0].split('-').reverse().join('/'),
            this.redirectUrl = this.editItem.redirect_url,
            this.imgAltText = this.editItem.image_alt_text,
            this.textColor = this.editItem.text_color,
            this.backgroundColor = this.editItem.background_color,
            this.sequence1 = 0
          this.$http.post(`${this.baseURL}/banners/get-sequence`).then((json) => {
            this.sequence1 = json.data.sequence - 1
          })
            this.$store.state.application = this.editItem.application_id,
            this.application_id = this.editItem.application_id,
          console.log(this.editItem)
          this.edit = true
      this.isLoading = false
          this.$forceUpdate();
        })
    },
    addBannerHandelClick() {
      if ((this.connectTo == 'Client' && this.client) || (this.connectTo == 'Coupon' && this.coupon) || (this.connectTo == 'Custom-URL' && this.customUrl)) {
        if (this.$store.state.application) {
          let data = {
            title: this.bannerTitleEn,
            title_ar: this.bannerTitleAr,
            connect_to: this.connectTo,
            client_id: this.client ? this.client.id : null,
            coupon_id: this.coupon ? this.coupon.id : null,
            custom_url: this.customUrl ? this.customUrl : null,
            image_url:this.imageUrl,
            image_url_ar:this.imageUrlAr,
            interface_id :this.interface ? this.interface.id : null,
            short_des_ar :this.shortDesAr,
            is_used_to_deal: this.isUsedToDeal ? 1 : 0,
            short_des_en :this.shortDesEn,
            start_date :this.startDate ? this.startDate.split('/').reverse().join('-') : '',
            end_date :this.endDate ? this.endDate.split('/').reverse().join('-') : '',
            redirect_url :this.redirectUrl,
            image_alt_text :this.imgAltText,
            text_color :this.textColor,
            background_color :this.backgroundColor,
            status:this.status ? 1 : 0,
            sequence: this.sequence,
            application_id: this.application_id ? this.application_id.id : this.$store.state.application.id,
          }
          if (this.edit == true) data = { ...data, id: this.editItem.id }

          if (this.sequence > this.sequence1) {
            this.$swal({
              title: "Warning!!",
              text: `Sequence must be Lower than ${this.sequence1}`,
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-warning",
              },
              buttonsStyling: false,
            });
          } else {
            
      this.$refs.submitPrevent.validate().then((success) => {
         if(!success){
        this.$swal({
                title: "Warning!!",
                text: `Please Select Require Fields`,
                icon: "warning",
                customClass: {
                  confirmButton: "btn btn-warning",
                },
                buttonsStyling: false,
              });
        }else{
          
                 this.isLoading = true
            this.$http.post(`${this.baseURL}/banners/${this.edit ? 'update' : 'create'}`, data,
              {
                headers: {
                  'Content-Type': 'application/json'
                },
                withCredentials: true
              }).then((json) => {
                console.log(json)
                this.isLoading = false
                if (json.data.status == 201) {
                  this.$swal({
                    title: "Submitted",
                    text: json.data.message
                      ? `${json.data.message}`
                      : json.data.success
                        ? `${json.data.success}`
                        : "Submitted SuccessFully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  })
                    this.handleBack()
                } else {
                  let text = Object.keys(json.data.data).length > 0 ? Object.keys(json.data.data).map(item => { return `${item}:${json.data.data[item]}` }) : json.data.message
                    ? `${json.data.message}`
                    : "Error"
                  this.$swal({
                    title: "Error!!",
                    text: text,
                    icon: "error",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
              })
            }
              })
          }
        } else {
          this.$swal({
            title: "Error!!",
            text: "Please Select Application First",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } else {
        this.$swal({
          title: "Error!!",
          text: `Please Select ${this.connectTo} First`,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    handelClickConnectTo() {
      this.client = ''
      this.coupon = ''
      this.customUrl = ''
    },
    async getCategories() {
      
      this.$http.post(`${this.baseURL}/banners/get-channels`).then((res) => {
        this.interfaceData = res.data.data
      })
      this.$http.post(`${this.baseURL}/client/get-all`).then((res) => {
        this.clientData = res.data.data
        this.clientData1 = res.data.data
      })
      this.$http.post(`${this.baseURL}/offers/get-all/${this.$store.state.application.id}`).then((res) => {
        this.offersData = res.data.data
        this.offersData1 = res.data.data
      })
    },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleBack() {
      this.$eventBus.$emit("handleClickCreate");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }

.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}

.mb-half {
  margin-bottom: 5px;
}

.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}

.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}

.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}

.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}

.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}

.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}

.popover-body {
  border: none !important;
}

.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}

.vs-select__options__content {
  background-color: #ffffff !important;
}

.pop-btn {
  padding: 0 !important;
}

.modal-dialog {
  // 
}

.modal-body {
   padding: 0px 40px 40px 40px !important
}

.form-control {
  border-radius: 14px;
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-radius: 24px;
  height:50px;
}

textarea.form-control {
  min-height: 150px !important;
}


.modal-footer {
  display: none;
}

.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}

.cate-vs-select {
  max-width: none;
}</style>
