<template>
  <section id="dashboard-ecommerce" class="font-14">
    <div v-if="bannerDetailsShow">
      <banner-details :editItemId="editItemId"/>
    </div>
    <div v-else>
      <div class="sec1 d-flex justify-content-between pb-1">
        <h3 class="text-black">Banner Management{{ search}}{{ app }}</h3>
        <div>
          <button
            id="popover-button-bottom"
            href="#"
            tabindex="0"
            type="button"
            class="btn notofication-btn mr-1"
            v-b-tooltip.hover.v-primary title="Filter"
            @click="onPopovcerOpen1()"
          >
            <div>
              <img
                src="../../../assets/images/client/filter.svg"
                alt="Snow"
              />
            </div>
          </button>

          <b-popover
            ref="popover"
            target="popover-button-bottom"
            triggers="click"
            placement="left"
            class="custom-popover pop-btn"
            :show.sync="popoverShow1"
          >
              
          <div class="center custom-vs-select" style="border: none;width:100%">
            <v-select :searchable="false" v-model="statusFilter" class="w-100" @input="getBanner('filter')" shadow :clearable="false"
              placeholder="Select" :options="['All', 'Active', 'Inactive']">
            </v-select>
            </div>
          </b-popover>

          <button
            type="button"
            class="btn btn-primary"
            style="border-radius: 16px; padding: 16px"
            @click="handleClickCreate"
          >
            Create
          </button>
        </div>
      </div>

      
    <div v-if="isLoading" style="height: 100vh;" class="d-flex justify-content-center align-items-center">
      <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
    </div>
    <div class="d-flex justify-content-center align-items-center" v-else-if="bannersData.length == 0">
      <img style="width:13cm; height:13cm" :src="host+'/NoDataFound.svg'" />
    </div>
    <div v-else class="drop-zone">
        <draggable
          @change="draggableChange($event)"
          v-model="bannersData"
          handle=".draggable-task-handle"
          tag="ul"
          class="todo-task-list media-list draggable-task-handle d-inline"
        >
          <b-card
            no-body
            class="card-revenue-budget dash-total-card mb-2 drag-el"
            v-for="(item, index) in bannersData"
            :key="index"
          >
            <b-row class="p-1">
              <b-col md="4" class="d-flex align-items-center pl-2">
                <div class="d-flex align-items-center">
                  <div class="pr-1">
                    <b-form-checkbox
                      v-if="popoverShow"
                      v-model="isRTL"
                      class="mr-0 mt-50"
                      name="is-rtl"
                      inline
                      @click="onPopovcerOpen()"
                    />
                  </div>
                  <div>
                    <div class="mb-half">Image</div>
                    <div class="font-weight-bolder text-black">
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col md="3" class="d-flex">
                <div>
                  <div class="mb-half">Sequence</div>
                  <div class="font-weight-bolder text-black">{{ item.sequence }}</div>
                </div>
              </b-col>
              <b-col md="3" class="d-flex ">
                <div>
                  <div class="mb-half">Status</div>
                  <div class="font-weight-bolder">
                    <div v-if="item.status" class="status-btn">
                      <strong><span class="boder">Active</span></strong>
                    </div>
                    <div v-else class="danger-btn">
                      <strong><span class="boder">Inactive</span></strong>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col
                md="2"
                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  text-black
                "
              >
                <div
                  style="background: #f4f9fd; padding: 8px; border-radius: 16px" @click="HandleClickEdit(item)"
                >
                  <feather-icon icon="EditIcon" size="25" />
                </div>
              </b-col>
            </b-row>
          </b-card>
        </draggable>
        <div class="no-results" :class="{ show: !bannersData.length }"></div>
      </div>

      <button v-if="!isLoading && bannersData.length" type="button" class="w-auto btn slide-btn">
        <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ paginationStart + 1 }} - {{ paginationEnd > paginationLength ?
          paginationLength : paginationEnd }} of {{ paginationLength }}</p>
        <feather-icon class="mx-1" icon="ArrowLeftIcon" @click="paginationHandelClick('back')" size="18" />
        <input v-model="pageNo" class="form-control"
          style="width:70px" @input="pagechange" type="number" />
        <feather-icon class="mx-1" icon="ArrowRightIcon" @click="paginationHandelClick('next')" size="18" />
      </div>

    </button>

    <button v-if="popoverShow && !isLoading" id="seeall-btn" type="button" class="btn see-btn" @click="selectAllClick()">
      <div class="d-flex justify-content-around align-items-center">
        <p class="text-black" style="margin: 0">{{ selectAllLabel }}</p>
      </div>
    </button>
    </div>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import BannerDetails from "./Banner-Details.vue";
import vSelect from "vue-select";
export default {
  components: {
    draggable,
    BannerDetails,
    vSelect
  },
  data() {
    return {
      bannerDetailsShow: false,
      selectedValue: "",
      selectAllLabel: 'Select All',
      data: {},
      paginationStart:0,
      paginationEnd:5,
      baseURL: process.env.VUE_APP_BASE_URL,
      host:window.location.origin,
      bannersData: [],
      bannersData1: [],
      popoverShow: false,
      pageNo:1,
      paginationLength: 0,
      popoverShow1: false,
      editItemId:'',
      isLoading:true,
      statusFilter: "All",
    };
  },
  mounted() {
    // this.getBanner()
    this.$eventBus.$on("handleClickCreate", () => {
      this.bannerDetailsShow = !this.bannerDetailsShow;
      this.getBanner();
    }); 
  },
  computed:{
      search() {
          // let data = []
          let search = this.$store.state.searchData
          // data = this.bannersData1.filter(item => ((item.status == 1 ? 'Active' : 'Inactive').toLowerCase().includes(search) 
          //                                              || item.title.replace(/\s/g,'').toLowerCase().includes(search)
          //                                              ))
          // this.bannersData = data
          if(this.$store.state.application.id){
          this.getSearch(search);
          }
        }, 
        app() {
          // this.getBanner()
        }
    },
  created() {},
  methods: {
    getSearch: _.debounce(function (search) {
      this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      // if(search){
      this.isLoading = true
      this.$http.post(`${this.baseURL}/banners/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id ,status:this.statusFilter },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res, 'res')
          this.bannersData = res.data.data
          this.paginationLength = res.data.totaldata
          this.bannersData1 = res.data.data
          this.isLoading = false
        })
      // }
    }, 1000),
    
    draggableChange(e) {
      console.log(e.moved, "e0-0=-=")
      let search = this.$store.state.searchData
      console.log(this.bannersData1[e.moved.newIndex].sequence, this.bannersData1[e.moved.oldIndex].sequence, "e0-0=-=")
      console.log(e.moved.newIndex, e.moved.oldIndex, "e0-0=-=")
      let data = {
        newIndex: this.bannersData1[e.moved.newIndex].sequence,
        oldIndex: this.bannersData1[e.moved.oldIndex].sequence,
        pageno: this.pageNo,
        app_id:this.$store.state.application.id,
        status: this.statusFilter
      }
      if (search) { data = { ...data, search: search } }
      this.$http.post(`${this.baseURL}/banners/sequence-change`, data,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }).then((res) => {
          console.log(res.data.data, "res-=-=")
          this.bannersData = []
          this.bannersData1 = []
          this.bannersData = res.data.data
          this.paginationLength = res.data.totaldata
          this.bannersData1 = res.data.data
        })
    },
    HandleClickEdit(item){
    this.editItemId = item.id
    this.bannerDetailsShow = !this.bannerDetailsShow;
    },
    pagechange() {
      if (this.pageNo > (this.paginationLength / 10)) {
        this.pageNo = parseInt(this.paginationLength / 10)
      }
      if (this.pageNo == 0) {
        this.pageNo = 1
      }
      this.paginationStart = (this.pageNo * 10) - 10
      this.paginationEnd = (this.pageNo * 10)
      let search = this.$store.state.searchData
      if (search) {
        this.searchPaginationHandelClick(search)
      } else {
        this.normalPaginationHandelClick(search)
      }
    },
    searchPaginationHandelClick: _.debounce(function (search) {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/banners/search`, { search: search, pageno: this.pageNo, app_id: this.$store.state.application.id,status:this.statusFilter }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.bannersData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.bannersData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    normalPaginationHandelClick: _.debounce(function () {
      this.isLoading = true
      this.$http.post(`${this.baseURL}/banners/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id,status:this.statusFilter }).then((res) => {
        console.log(res.data.data, "res-=-=")
        this.bannersData = res.data.data
        // this.pageNo=res.data.page
        this.selectAllLabel = 'Select All'
        this.checkBoxIds = {}
        this.paginationLength = res.data.totaldata
        this.bannersData1 = res.data.data
        this.isLoading = false
      })
    }, 1000),
    paginationHandelClick(value) {
      this.checkBoxIds = {}
      let search = this.$store.state.searchData
      if (value == 'back') {
        if (this.paginationStart > 1) {
          this.pageNo--
          this.paginationStart = this.paginationStart - 10
          this.paginationEnd = this.paginationEnd - 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
      else if (value == 'next') {
        if (this.paginationEnd < this.paginationLength) {
          this.pageNo++
          this.paginationStart = this.paginationStart + 10
          this.paginationEnd = this.paginationEnd + 10
          if (search) {
            this.searchPaginationHandelClick(search, value)
          } else {
            this.normalPaginationHandelClick(search, value)
          }
        }
      }
    },

    
    async getBanner(filter){
      this.popoverShow1 = false
      if(filter == 'filter'){
        this.pageNo = 1
      this.paginationStart = 0
      this.paginationEnd = 10
      }
      if (this.$store.state.application) {
      this.isLoading = true
        this.$http.post(`${this.baseURL}/banners/index`, { pageno: this.pageNo, app_id: this.$store.state.application.id,status:this.statusFilter }).then((res) => {
            console.log(res.data.data,"res-=-=")
            this.bannersData=res.data.data
            this.paginationLength = res.data.totaldata
            this.bannersData1=res.data.data
            this.isLoading = false
          })
        }
        },
    onPopovcerOpen() {
      this.popoverShow = !this.popoverShow;
    },
    onPopovcerOpen1() {
      this.popoverShow1 = !this.popoverShow1;
    },
    handleClickCreate() {
      this.editItemId = null
      this.bannerDetailsShow = !this.bannerDetailsShow;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
// .draggable-task-handle {
//     transform: translateY(-50%);
//     visibility:visible;
//     cursor: move;

//     .todo-task-list .todo-item:hover & {
//       visibility: hidden;
//     }
// }
.danger-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #c90a0a;
  border: 1px solid #c90a0a;
  border-radius: 4px;
}
.status-btn {
  width: 58px;
  padding: 3px 5px;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #0ac947;
  border: 1px solid #0ac947;
  border-radius: 4px;
}
.mb-half {
  margin-bottom: 5px;
}
.notofication-btn {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  padding: 12px 12px;
}
.slide-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  padding: 15px;
  margin: 0;
  width: 200px;
  float: right;
}
.see-btn {
  background: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.103611);
  border-radius: 14px;
  border-radius: 16px;
  width: 125px;
}
.bs-popover-left {
  border-radius: 14px !important;
  border: transparent;
  min-width: 140px !important;
  max-width: 350px !important;
}
.bs-popover-left .popover-body {
  display: flex;
  justify-content: space-between;
  border: none;
}
.bs-popover-bottom {
  background-color: #ffffff;
  // height: 190px;
  border: transparent;
  border: none;
  border-radius: 14px;
}
.popover-body {
  border: none !important;
}
.popover.bs-popover-bottom .arrow:after {
  border-bottom-color: #ffffff;
}
.vs-select__options__content {
  background-color: #ffffff !important;
}
.pop-btn {
  padding: 0 !important;
}
.modal-body {
   padding: 0px 40px 40px 40px !important
}
.form-control {
  border-radius: 14px;
}
.modal-content {
  border-radius: 24px;
}
.modal-header {
  border-radius: 24px;
}
textarea.form-control {
  min-height: 150px !important;
}

.modal-footer {
  display: none;
}
.modal-footer1 {
  display: flex;
  float: right;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.8rem 1.4rem;
}
.cate-vs-select {
  max-width: none;
}
</style>
